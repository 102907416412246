<template>
  <section class="outContainer">
    <div class="back">
        <i class="el-icon-back" @click="back"></i>
        <span>{{this.$route.query.id}}通讯记录</span>
    </div>
    <!-- 筛选条件栏 -->
    <div class="search">
        <el-select class='select each' v-model="searchInfo.param.type" clearable placeholder="通讯类型" @change="changeType">
          <el-option
            v-for="item in communicationOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
    </div>
    <div class="tableContainer">
      <el-table :data="tableList" height="100%">
          <el-table-column align="center" label="指令" prop="action">
          </el-table-column>
          <el-table-column align="center" label="版本" prop="hardwareVersion">
              <template slot-scope="scope">
                  <p>硬件：{{ scope.row.hardwareVersion ? scope.row.hardwareVersion : '--' }}</p>
                  <p>软件：{{ scope.row.softwareVersion ? scope.row.softwareVersion : '--' }}</p>
              </template>
          </el-table-column>
          <el-table-column align="center" label="是否上锁" prop="lockName">
          </el-table-column>
          <el-table-column align="center" label="是否停放车辆" prop="parkingName">
          </el-table-column>
          <el-table-column align="center" label="错误码" prop="errorCode">
          </el-table-column>
          <el-table-column align="center" label="错误信息" prop="errorMsg">
          </el-table-column>
          <el-table-column align="center" label="创建时间" prop="createDatetimeStr"></el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <el-col :span="24" class="toolbar pagination">
      <el-pagination
        background
        layout=" prev , next , jumper"
        @current-change="handleCurrentChange"
        :page-size="10"
        style="float:right;"
      ></el-pagination>
    </el-col>
  </section>
</template>

<script>
import { getFloorLockRecords } from '@/api/public.js';
import { timeChange } from '@/api/publicFun.js';
export default {
  data() {
    return {
        searchInfo:{ // 筛选条件
            pageNo:1,
            pageSize:10,
            param:{
                deviceId:'',
                type:null,
            }
        },
        tableList:[], // 表格数据
        communicationOptions:[ // 通讯类型选项
            {value:1,label:'心跳'},
            {value:2,label:'控锁'},
        ],
    }
  },

  components: {},

  computed: {},

  mounted() {
    this.searchInfo.param.deviceId = this.$route.query.id;
    this.init();
  },

  methods: {
    // 返回上一页
    back(){
      this.$router.go(-1);
    },
    // 选择通讯类型
    changeType(e){
      this.searchInfo.pageNo = 1;
      this.searchInfo.param.type = e && e != "" ? e : null; 
      this.handleCurrentChange(1);
      // this.init();
    },
    // 获取表格数据
    async init(){
      await getFloorLockRecords(this.searchInfo).then(res=>{
        if(res.success){
          if( !res.data.list || res.data.list.length == 0){
            this.tableList = [];
            this.$message({
              message:'没有更多通讯记录了',
              type:'remind'
            })
          }else{
            this.tableList = res.data.list;
            this.tableList.forEach(element => {
              element.createDatetimeStr = timeChange(element.createDatetime,'seconds');
            });
          }
        }else{
          this.$message({
            message:res.msg,
            type:'fail'
          })
          this.tableList = [];
        }
      })
    },
    // 分页操作事件
    handleCurrentChange(val){
      this.searchInfo.pageNo = val;
      this.init();
    }
  },
};
</script>
<style scoped></style>
